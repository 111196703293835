import React from "react"
import {
  faInstagram,
  faLinkedin,
  faTwitter,
  faStackOverflow,
  faGithub,
  faDribbble
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./socialMediaIcon.module.scss"

const socialMediaIcon = () => {
  return (
    <div className={styles.socialIconContainer}>
      <a
        className={styles.socialIcon}
        aria-label="Instagram"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/jephtecolin/"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        className={styles.socialIcon}
        aria-label="LinkedIn"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/in/jephte-colin-504660168/"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        className={styles.socialIcon}
        aria-label="Twitter"
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/jephte_colin"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        className={styles.socialIcon}
        aria-label="Stackoverflow"
        target="_blank"
        rel="noopener noreferrer"
        href="https://stackoverflow.com/users/9662401/dr4ke-the-b4dass"
      >
        <FontAwesomeIcon icon={faStackOverflow} />
      </a>
      <a
        className={styles.socialIcon}
        aria-label="Github"
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/Draketheb4dass"
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a
        className={styles.socialIcon}
        aria-label="Dribbble"
        target="_blank"
        rel="noopener noreferrer"
        href="https://dribbble.com/draketheb4dass"
      >
        <FontAwesomeIcon icon={faDribbble} />
      </a>
    </div>
  )
}

export default socialMediaIcon
