import React from "react"
import styles from "./contactButton.module.scss"
import { Link } from "gatsby"

const ContactButton = () => {
  return (
    <Link to="/contact">
      <button className={styles.buttonGradient}>Let's talk!</button>
    </Link>
  )
}
export default ContactButton
