import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typewriter from "typewriter-effect"
import styles from "./index.module.scss"
import ContactButton from "../components/contactButton"
import SocialMediaIcon from "../components/socialMediaIcon"

const IndexPage = () => {
  return (
    <div>
      <Layout>
        <SEO title="Home" />
        <div className={styles.indexStyle}>
          <SocialMediaIcon />
          <h1>
            Hi, I'm <span style={{ color: "#04BD92" }}>Jephte Colin.</span>
            <br /> I'm a Software Engineer
          </h1>
          <h2>
            <span>
              <Typewriter
                onInit={typewriter => {
                  typewriter
                    .typeString(
                      '<span style="color: magenta; font-size: 50px; font-weight: 800">Mobile Apps</span>'
                    )
                    .callFunction(() => {
                      console.log("String typed out!")
                    })
                    .pauseFor(1000)
                    .deleteChars(11)
                    .typeString(
                      '<span style="color: magenta; font-size: 50px; font-weight: 800">Websites</span>'
                    )
                    .pauseFor(1000)
                    .deleteChars(8)
                    .typeString(
                      '<span style="color: magenta; font-size: 50px; font-weight: 800">APIs</span>'
                    )
                    .pauseFor(1000)
                    .deleteChars(8)
                    .typeString(
                      '<span style="color: magenta; font-size: 50px; font-weight: 800">SEO</span>'
                    )
                    .pauseFor(1000)
                    .deleteChars(8)
                    .typeString(
                      '<span style="color: magenta; font-size: 50px; font-weight: 800">UX/UI Design</span>'
                    )
                    .pauseFor(1000)
                    .deleteChars(4)
                    .callFunction(() => {
                      console.log("All strings were deleted")
                    })
                    .start()
                }}
                options={{
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </h2>
        </div>
        <ContactButton />
      </Layout>
    </div>
  )
}

export default IndexPage
